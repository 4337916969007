import React from "react";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  formPageMiddleware,
  navigationMiddleware,
} from "middleware/fields_page";

import {
  CurrentFormOrSubform,
  EditLabelIdentifier,
  NavigationReducer,
  BulkActionsReducer,
  bulkActionsDefaultState,
  addFieldDefaultState,
  addFieldReducer,
  EditDependencies,
  EditSettings,
  editTypeReducer,
  editTypeDefaultState,
  ValidationRules,
} from "reducers/fields_page";

import {
  NavigationScreen,
  FieldTableScreen,
  EditTypeScreen,
  BulkActionsScreen,
  EditLabelIdentifierFormScreen,
  AddFieldFormScreen,
  EditDependenciesFormScreen,
  EditSettingsFormScreen,
  ValidationRulesFormScreen,
} from "components/fields_page";

const FieldsPage = ({
  studyId,
  forms,
  allForms,
  subforms,
  allSubforms,
  currentFormOrSubform,
  availableFieldTypes,
  widgetMap,
  lookupOptions,
  dynamicLookupOptions,
  tableOptions,
  tableColumnOptions,
  libraryCalculationOptions,
  navFilter,
  highlightedFields,
}) => {
  const isCurrentFormOrSubform = (form) => {
    return (
      form.id === currentFormOrSubform.id &&
      form.class === currentFormOrSubform.class
    );
  };

  const listWithCurrentFormOrSubformAtFront = (list) => {
    const position = list.findIndex(isCurrentFormOrSubform);
    if (position !== -1) {
      const element = list[position];
      list.splice(position, 1);
      list.splice(0, 0, element);
    }

    return list;
  };

  const optionLabelFor = (form) => {
    if (currentFormOrSubform.class === "form") {
      return isCurrentFormOrSubform(form)
        ? `${form.name} - [Current form]`
        : form.name;
    } else if (currentFormOrSubform.class === "subform") {
      return isCurrentFormOrSubform(form)
        ? `${form.name} - [Current subform]`
        : form.name;
    }
  };

  const formTypeToClassMapping = {
    form: "StudyForm",
    subform: "Subform",
  };

  const optionsForSelect = (formOrSubformList) => {
    return formOrSubformList.map((form) => {
      return {
        label: optionLabelFor(form),
        value: form.id,
        type: formTypeToClassMapping[form.class],
      };
    });
  };

  const availableForms = () => {
    return [
      {
        label: "Forms",
        options: optionsForSelect(
          listWithCurrentFormOrSubformAtFront(allForms)
        ),
      },
      {
        label: "Subforms",
        options: optionsForSelect(
          listWithCurrentFormOrSubformAtFront(allSubforms)
        ),
      },
    ];
  };

  const store = createStore(
    combineReducers({
      currentFormOrSubform: CurrentFormOrSubform,
      editTypeModal: editTypeReducer,
      editLabelIdentifierForm: EditLabelIdentifier,
      bulkActions: BulkActionsReducer,
      navigation: NavigationReducer,
      addField: addFieldReducer,
      editDependencies: EditDependencies,
      editSettings: EditSettings,
      validationRules: ValidationRules,
    }),
    {
      currentFormOrSubform: currentFormOrSubform,
      editTypeModal: {
        ...editTypeDefaultState,
        widgetTypeMap: widgetMap,
        allLookupOptions: lookupOptions,
        dynamicLookupOptions: dynamicLookupOptions,
        lookupOptions: lookupOptions,
      },
      bulkActions: {
        ...bulkActionsDefaultState,
        studyId: studyId,
        availableForms: availableForms(),
      },
      navigation: {
        studyId: studyId,
        forms: forms,
        subforms: subforms,
        highlightedFields: highlightedFields,
        currentFormOrSubform: currentFormOrSubform,
        navFilter: navFilter,
      },
      addField: {
        ...addFieldDefaultState,
        currentFormOrSubform: currentFormOrSubform,
        availableTypes: availableFieldTypes,
        widgetOptions: [],
        widgetTypeMap: widgetMap,
        allLookupOptions: lookupOptions,
        dynamicLookupOptions: dynamicLookupOptions,
        lookupOptions: lookupOptions,
        studyId: studyId,
        saving: false,
        values: {
          type: "",
          identifier: "",
          label: "",
          default_presence_validation: true,
          default_disallow_future_validation: true,
        },
      },
      editDependencies: {
        showForm: false,
        saving: false,
        loadingFields: true,
        fieldsForDependencies: [],
        field: {},
        values: {},
        errors: {},
      },
      editSettings: {
        showForm: false,
        saving: false,
        field: {},
        values: {},
        errors: {},
        tableOptions: tableOptions,
        tableColumnOptions: tableColumnOptions,
        availableLibraryCalculationFields: currentFormOrSubform.fields.map(
          (field) => {
            return { label: field.identifier, value: field.identifier };
          }
        ),
        libraryCalculationOptions: libraryCalculationOptions,
        allFieldsForCalculations: [],
        localFieldsForCalculations: currentFormOrSubform.fields.map((field) => {
          return { label: field.identifier, value: field.identifier };
        }),
      },
    },
    applyMiddleware(formPageMiddleware, navigationMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <NavigationScreen />
        <FieldTableScreen />
        <EditLabelIdentifierFormScreen />
        <EditTypeScreen />
        <BulkActionsScreen />
        <AddFieldFormScreen />
        <EditDependenciesFormScreen />
        <EditSettingsFormScreen />
        <ValidationRulesFormScreen />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default FieldsPage;

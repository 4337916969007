import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ButtonToolbar,
  Button,
  Checkbox,
  Col,
  Modal,
  Row,
  Alert,
} from "react-bootstrap";

import ValidationFields from "./ValidationFields";

import { TextAreaFieldInputGroup } from "components/helpers";
import AceEditor from "../AceEditor";
import {
  SelectInputGroup,
  TextField,
  TextAreaField,
  PrivacyTagsSelect,
} from "../helpers";
import actions from "actions/fields_page";

const ValidationRulesFormScreen = ({
  editing,
  field,
  validation,
  values,
  lookupOptions,
  collections,
  validationFields,
  conditionFields,
  loadingValidationFields,
  loadingConditionFields,
  errors,
  show,
  saving,
  handleClose,
  updateFormValue,
  handleCreateSubmit,
  handleUpdateSubmit,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (editing) {
      handleUpdateSubmit(validation.id, values);
    } else {
      handleCreateSubmit(field.study_id, field.id, values);
    }
  };

  let existingValidations = field.validation_rules
    ? field.validation_rules.map((validation) => validation.name)
    : [];

  const hasValidation = (validation) => {
    return existingValidations.includes(validation);
  };

  const customMessageShow =
    values.use_custom_message ||
    errors.custom_message ||
    values.validation_rule_type == "generic";

  return (
    <Modal
      id="modalWindow"
      dialogClassName="modal-lg validation-form-modal"
      backdrop="static"
      show={show}
      onHide={saving ? undefined : handleClose}
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>
            {`${
              editing ? `Edit ${validation.name}` : "Add"
            } validation rule for ${field.identifier}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.base && <Alert bsStyle="danger">{errors.base[0]}</Alert>}
          {loadingValidationFields && values.validation_rule_type && (
            <span className="loading-status margin-left-0">
              <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
              Loading available fields
            </span>
          )}
          <ValidationFields
            field={field}
            saving={saving}
            editing={editing}
            availableFields={validationFields}
            loadingFields={loadingValidationFields}
            values={values}
            errors={errors}
            updateFormValue={updateFormValue}
            hasValidation={hasValidation}
            lookupOptions={lookupOptions}
            collections={collections}
          />
          {values.validation_rule_type && (
            <>
              <fieldset className="margin-top-30">
                <legend className="margin-bottom-15">Applies to</legend>
                <Row>
                  <Col md={6}>
                    <SelectInputGroup
                      label="PoE"
                      field="pre_entry"
                      value={values.pre_entry}
                      disabled={saving}
                      errors={errors.pre_entry}
                      onChange={updateFormValue}
                      optionsForSelect={collections.pre_entry}
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInputGroup
                      label="Post-entry"
                      field="post_entry"
                      value={values.post_entry}
                      disabled={saving}
                      errors={errors.post_entry}
                      onChange={updateFormValue}
                      optionsForSelect={collections.post_entry}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <SelectInputGroup
                      label="Priority"
                      field="priority"
                      value={values.priority}
                      disabled={saving}
                      errors={errors.priority}
                      onChange={updateFormValue}
                      optionsForSelect={collections.priority}
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInputGroup
                      label="Permission level"
                      field="permission_level"
                      value={values.permission_level}
                      disabled={saving}
                      errors={errors.permission_level}
                      onChange={updateFormValue}
                      optionsForSelect={collections.permission_level}
                    />
                  </Col>
                </Row>
                <Row>
                  {values.permission_level == "restricted" && (
                    <Col md={6} mdOffset={6}>
                      <PrivacyTagsSelect
                        collections={collections}
                        values={values}
                        errors={errors}
                        saving={saving}
                        updateFormValue={updateFormValue}
                      />
                    </Col>
                  )}
                </Row>
              </fieldset>
              <fieldset className="margin-top-30" id="custom-message-section">
                <legend className="margin-bottom-15">Custom message</legend>
                <Row>
                  <Col md={6}>
                    <TextField
                      label="Custom error label (if left blank the label of the field will be used)"
                      controlId="custom_error_label"
                      fieldName="custom_error_label"
                      value={values.custom_error_label}
                      disabled={saving}
                      errors={errors.custom_error_label}
                      onChange={updateFormValue}
                    />
                  </Col>
                </Row>
                {values.validation_rule_type == "generic" || (
                  <Checkbox
                    disabled={saving}
                    checked={customMessageShow || ""}
                    validationState={errors.use_custom_message ? "error" : null}
                    onChange={(e) =>
                      updateFormValue("use_custom_message", e.target.checked)
                    }
                  >
                    Use custom message
                  </Checkbox>
                )}
                {customMessageShow &&
                  values.validation_rule_type !== "range" && (
                    <TextAreaField
                      rows={3}
                      controlId="custom_message"
                      fieldName="custom_message"
                      value={values.custom_message}
                      disabled={saving}
                      errors={errors.custom_message}
                      onChange={updateFormValue}
                    />
                  )}
                {customMessageShow && values.validation_rule_type === "range" && (
                  <Row>
                    <Col md={6}>
                      <TextAreaFieldInputGroup
                        label="Min"
                        field="min_custom_message"
                        value={values.min_custom_message}
                        disabled={saving}
                        errors={errors.min_custom_message}
                        onChange={updateFormValue}
                        optionsForSelect={[]}
                      />
                    </Col>

                    <Col md={6}>
                      <TextAreaFieldInputGroup
                        label="Max"
                        field="max_custom_message"
                        value={values.max_custom_message}
                        disabled={saving}
                        errors={errors.max_custom_message}
                        onChange={updateFormValue}
                        optionsForSelect={[]}
                      />
                    </Col>
                  </Row>
                )}

                {customMessageShow && values.validation_rule_type === "range" && (
                  <Row>
                    <Col md={6}>
                      <TextAreaFieldInputGroup
                        label="Low"
                        field="low_custom_message"
                        value={values.low_custom_message}
                        disabled={saving}
                        errors={errors.low_custom_message}
                        onChange={updateFormValue}
                        optionsForSelect={[]}
                      />
                    </Col>

                    <Col md={6}>
                      <TextAreaFieldInputGroup
                        label="High"
                        field="high_custom_message"
                        value={values.high_custom_message}
                        disabled={saving}
                        errors={errors.high_custom_message}
                        onChange={updateFormValue}
                        optionsForSelect={[]}
                      />
                    </Col>
                  </Row>
                )}
              </fieldset>
              <fieldset className="margin-top-30">
                <legend className="margin-bottom-15">Condition</legend>
                <AceEditor
                  disabled={saving}
                  fieldName="run_if"
                  value={values.run_if}
                  errors={errors.run_if}
                  handleUpdateValue={updateFormValue}
                  loadingFields={loadingConditionFields}
                  fieldsForDependencies={conditionFields}
                />
              </fieldset>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" type="submit" disabled={saving}>
              {saving && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                  Saving
                </span>
              )}
              {!saving && (
                <span>
                  <i className="fa fa-check margin-right-5" />
                  Save
                </span>
              )}
            </Button>
            <div className="pull-right">
              <Button bsStyle="default" disabled={saving} onClick={handleClose}>
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.validationRules;

  return {
    show: relevantState.showForm,
    field: relevantState.field,
    lookupOptions: relevantState.lookupOptions,
    values: relevantState.values,
    collections: relevantState.collections,
    errors: relevantState.errors,
    validation: relevantState.validation,
    saving: relevantState.saving,
    editing: relevantState.editing,
    validationFields: relevantState.validationFields,
    conditionFields: relevantState.conditionFields,
    loadingValidationFields: relevantState.loadingValidationFields,
    loadingConditionFields: relevantState.loadingConditionFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFormValue: actions.updateValidationRulesFormValue,
      handleClose: actions.handleCloseValidationRulesForm,
      handleCreateSubmit: actions.handleNewValidationRulesFormSubmit,
      handleUpdateSubmit: actions.handleEditValidationRulesFormSubmit,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidationRulesFormScreen);

import React from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, ControlLabel, HelpBlock } from "react-bootstrap";

import { NumberField, TextField, Select } from "components/helpers";

const FieldTypeSettings = ({
  fieldType,
  availableTypes,
  widgetOptions,
  widgetType,
  unit,
  precision,
  saving,
  errors,
  handleFieldTypeChange,
  handleWidgetChange,
  handleUnitChange,
  handlePrecisionChange,
}) => {
  const isUnitVisible = () => {
    return (
      fieldType === "FormFields::IntegerType" ||
      fieldType === "FormFields::DecimalType"
    );
  };

  const isPrecisionVisible = () => {
    return fieldType === "FormFields::DecimalType";
  };

  const customFieldTypeStyle = {
    menuList: () => ({
      maxHeight: "max-content",
    }),
  };

  const formErrors = errors.field;

  return (
    <>
      <Row>
        <Col md={6}>
          <FormGroup
            controlId="select-field-type"
            validationState={formErrors.type ? "error" : null}
          >
            <ControlLabel>
              <abbr title="required">*</abbr>
              &nbsp; Field type
            </ControlLabel>
            <Select
              placeholder="Select field type"
              options={availableTypes}
              className="select-field-type"
              classNamePrefix="select-field-type"
              value={
                availableTypes.find((option) => {
                  return option.value === fieldType;
                }) || ""
              }
              onChange={handleFieldTypeChange}
              isDisabled={saving}
              styles={customFieldTypeStyle}
            />
            {formErrors.type && (
              <HelpBlock bsClass="inline-help-block">
                {formErrors.type[0]}
              </HelpBlock>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <div className="form-group select optional form_field_widget">
            <label htmlFor="field-widget" className="select optional">
              Widget
            </label>
            <Select
              id="field-widget"
              options={widgetOptions}
              className="select-field-widget"
              classNamePrefix="select-field-widget"
              value={
                widgetOptions.find((option) => {
                  return option.value === widgetType;
                }) || ""
              }
              onChange={handleWidgetChange}
              isDisabled={saving}
            />
          </div>
        </Col>
      </Row>
      <Row>
        {isUnitVisible() && (
          <Col md={6}>
            <TextField
              fieldName="unit"
              controlId="unit"
              label="Unit"
              value={unit}
              disabled={saving}
              errors={formErrors.unit}
              onChange={handleUnitChange}
            />
          </Col>
        )}
        {isPrecisionVisible() && (
          <Col md={6}>
            <NumberField
              fieldName="precision"
              required={true}
              controlId="precision"
              label="Precision"
              value={precision}
              disabled={saving}
              errors={formErrors.precision}
              onChange={handlePrecisionChange}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  let modalState, field, fieldType;
  if (state.addField.showForm) {
    modalState = state.addField;
    field = modalState.values;
    fieldType = field.type;
  } else {
    modalState = state.editTypeModal;
    field = modalState.field;
    fieldType = field.field_type;
  }
  return {
    fieldType: fieldType,
    availableTypes: field.field_type_options || modalState.availableTypes || [],
    widgetOptions: modalState.widgetOptions || [],
    widgetType: field.widget_type,
    unit: field.unit || "",
    precision: field.precision || "",
    saving: modalState.saving,
    errors: modalState.errors,
  };
};

export default connect(mapStateToProps)(FieldTypeSettings);

import * as ActionTypes from "./action_types";

export const updateFormValue = (fieldName, value) => ({
  type: ActionTypes.UPDATE_FORM_VALUE,
  fieldName,
  value,
});

export const handleEditLabelIdentifierFormOpen = (field) => ({
  type: ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_OPEN,
  field,
});

export const handleEditLabelIdentifierFormClose = () => ({
  type: ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_CLOSE,
});

export const handleEditLabelIdentifierFormSubmit = (
  studyId,
  fieldId,
  values
) => ({
  type: ActionTypes.HANDLE_FIELD_LABEL_OR_IDENTIFIER_FORM_SUBMIT,
  studyId: studyId,
  fieldId: fieldId,
  values: values,
});

export const handleUpdateDetailsError = (errors) => ({
  type: ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_ERROR,
  errors: errors,
});

export const handleUpdateDetailsSuccess = (fields) => ({
  type: ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_SUCCESS,
  fields: fields,
});

export const handleSelectAllFieldsForBulkAction = (studyId, fields) => ({
  type: ActionTypes.HANDLE_SELECT_ALL_FIELDS_FOR_BULK_ACTION,
  studyId: studyId,
  fields: fields,
});

export const handleRemoveAllSelectedFieldsForBulkAction = () => ({
  type: ActionTypes.HANDLE_REMOVE_ALL_SELECTED_FIELDS_FOR_BULK_ACTION,
});

export const handleSelectFieldForBulkAction = (studyId, field) => ({
  type: ActionTypes.HANDLE_SELECT_FIELD_FOR_BULK_ACTION,
  studyId: studyId,
  field: field,
});

export const handleRemoveSelectedFieldForBulkAction = (field) => ({
  type: ActionTypes.HANDLE_REMOVE_SELECTED_FIELD_FOR_BULK_ACTION,
  field: field,
});

export const handleSelectTargetForm = (targetForm) => ({
  type: ActionTypes.HANDLE_SELECT_TARGET_FORM,
  targetForm: targetForm,
});

export const handleSelectCopyNTimes = (nTimes) => ({
  type: ActionTypes.HANDLE_SELECT_COPY_N_TIMES,
  nTimes: nTimes,
});

export const handleShowBulkActionConfirmation = (confirmationType) => ({
  type: ActionTypes.HANDLE_SHOW_BULK_ACTION_CONFIRMATION,
  confirmationType: confirmationType,
});

export const handleHideBulkActionConfirmation = () => ({
  type: ActionTypes.HANDLE_HIDE_BULK_ACTION_CONFIRMATION,
});

export const handleDeleteSelectedFields = (studyId, fields) => ({
  type: ActionTypes.HANDLE_DELETE_SELECTED_FIELDS,
  studyId: studyId,
  fields: fields,
});

export const handleMoveSelectedFields = (
  studyId,
  target_id,
  target_type,
  source_id,
  source_type,
  fields
) => ({
  type: ActionTypes.HANDLE_MOVE_SELECTED_FIELDS,
  studyId: studyId,
  target_id: target_id,
  target_type: target_type,
  source_id: source_id,
  source_type: source_type,
  fields: fields,
});

export const handleCopySelectedFields = (
  studyId,
  target_id,
  target_type,
  source_id,
  source_type,
  fields,
  nTimes
) => ({
  type: ActionTypes.HANDLE_COPY_SELECTED_FIELDS,
  studyId: studyId,
  target_id: target_id,
  target_type: target_type,
  source_id: source_id,
  source_type: source_type,
  fields: fields,
  nTimes: nTimes,
});

export const handleDeleteSelectedFieldSuccess = (responseData) => ({
  type: ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_SUCCESS,
  removedFields: responseData.successFields,
  totalValidations: responseData.total_validations,
});

// This is if some of the fields could not be deleted
export const handleDeleteSelectedFieldWithErrors = (responseData) => ({
  type: ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_WITH_ERRORS,
  successFields: responseData.successFields,
  failedFields: responseData.failedFields,
});

// This is if the request fails completely
export const handleBulkActionsError = () => ({
  type: ActionTypes.HANDLE_BULK_ACTIONS_ERROR,
});

export const handleMoveSelectedFieldSuccess = (
  fields,
  target_id,
  target_type
) => ({
  type: ActionTypes.HANDLE_MOVE_SELECTED_FIELDS_SUCCESS,
  fields: fields,
  target: { id: target_id, type: target_type },
});

export const handleMoveSelectedFieldsWithErrors = (
  moveData,
  target_id,
  target_type
) => ({
  type: ActionTypes.HANDLE_MOVE_SELECTED_FIELDS_WITH_ERRORS,
  moveData: moveData,
  target: { id: target_id, type: target_type },
});

export const handleCopySelectedFieldSuccess = (
  copyData,
  target_id,
  target_type
) => ({
  type: ActionTypes.HANDLE_COPY_SELECTED_FIELDS_SUCCESS,
  allFormFields: copyData.allFormFields,
  successFields: copyData.successFields,
  totalValidations: copyData.total_validations,
  target: { id: target_id, type: target_type },
});

export const handleCopySelectedFieldsWithErrors = (
  copyData,
  target_id,
  target_type
) => ({
  type: ActionTypes.HANDLE_COPY_SELECTED_FIELDS_WITH_ERRORS,
  allFormFields: copyData.allFormFields,
  successFields: copyData.successFields,
  failedFields: copyData.failedFields,
  totalValidations: copyData.total_validations,
  target: { id: target_id, type: target_type },
});

export const handleHideBulkActionError = () => ({
  type: ActionTypes.HANDLE_HIDE_BULK_ACTION_ERROR,
});

export const handleSetNavFilter = (navFilter) => ({
  type: ActionTypes.HANDLE_SET_NAV_FILTER,
  navFilter: navFilter,
});

export const handleNavSearch = (studyId, navFilter) => ({
  type: ActionTypes.HANDLE_NAV_SEARCH,
  studyId: studyId,
  navFilter: navFilter,
});

export const handleNavSearchSuccess = (searchData) => ({
  type: ActionTypes.HANDLE_NAV_SEARCH_SUCCESS,
  searchData: searchData,
});

export const handleOpenNewFieldForm = () => ({
  type: ActionTypes.HANDLE_OPEN_NEW_FIELD_FORM,
});

export const handleCloseNewFieldForm = () => ({
  type: ActionTypes.HANDLE_CLOSE_NEW_FIELD_FORM,
});

export const updateNewFieldFormValue = (fieldName, value) => ({
  type: ActionTypes.UPDATE_NEW_FIELD_FORM_VALUE,
  fieldName,
  value,
});

export const handleNewFieldFormSubmit = (
  studyId,
  values,
  fieldableId,
  fieldableType,
  formSectionId,
  newLookup,
  newLookupOptions
) => ({
  type: ActionTypes.HANDLE_NEW_FIELD_FORM_SUBMIT,
  studyId: studyId,
  values: values,
  fieldableId: fieldableId,
  fieldableType: fieldableType,
  formSectionId: formSectionId,
  newLookup: newLookup,
  newLookupOptions: newLookupOptions,
});

export const handleNewFieldFormSubmitAndClose = (
  studyId,
  values,
  fieldableId,
  fieldableType,
  formSectionId,
  newLookup,
  newLookupOptions
) => ({
  type: ActionTypes.HANDLE_NEW_FIELD_FORM_SUBMIT_AND_CLOSE,
  studyId: studyId,
  values: values,
  fieldableId: fieldableId,
  fieldableType: fieldableType,
  formSectionId: formSectionId,
  newLookup: newLookup,
  newLookupOptions: newLookupOptions,
});

export const handleNewFieldFormError = (field) => ({
  type: ActionTypes.HANDLE_NEW_FIELD_FORM_ERROR,
  field: field,
});

export const handleNewFieldFormSuccess = (data, target) => ({
  type: ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS,
  data: data,
  target: target,
});

export const handleNewFieldFormSuccessAndClose = (data, target) => ({
  type: ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS_AND_CLOSE,
  data: data,
  target: target,
});

export const handleOpenEditDependenciesForm = (field) => ({
  type: ActionTypes.HANDLE_OPEN_EDIT_DEPENDENCIES_FORM,
  field: field,
});

export const handleGetFieldsForDependencies = (fields) => ({
  type: ActionTypes.HANDLE_GET_FIELDS_FOR_DEPENDENCIES,
  fields: fields,
});

export const handleCloseEditDependenciesForm = () => ({
  type: ActionTypes.HANDLE_CLOSE_EDIT_DEPENDENCIES_FORM,
});

export const updateEditDependenciesFormValue = (fieldName, value) => ({
  type: ActionTypes.UPDATE_EDIT_DEPENDENCIES_FORM_VALUE,
  fieldName: fieldName,
  value: value,
});

export const handleSubmitEditDependenciesForm = (studyId, fieldId, values) => ({
  type: ActionTypes.HANDLE_SUBMIT_EDIT_DEPENDENCIES_FORM,
  studyId: studyId,
  fieldId: fieldId,
  values: values,
});

export const handleEditDependenciesFormSuccess = (fields) => ({
  type: ActionTypes.HANDLE_EDIT_DEPENDENCIES_FORM_SUCCESS,
  fields: fields,
});

export const handleEditDependenciesFormError = (errors) => ({
  type: ActionTypes.HANDLE_EDIT_DEPENDENCIES_FORM_ERROR,
  errors: errors,
});

export const handleOpenSettingsForm = (field) => ({
  type: ActionTypes.HANDLE_OPEN_SETTINGS_FORM,
  field: field,
});

export const handleGetFieldsForCalculations = (fields) => ({
  type: ActionTypes.HANDLE_GET_FIELDS_FOR_CALCULATIONS,
  fields: fields,
});

export const updateSettingsFormValue = (fieldName, value) => ({
  type: ActionTypes.UPDATE_SETTINGS_FORM_VALUE,
  fieldName: fieldName,
  value: value,
});

export const handleCloseSettingsForm = () => ({
  type: ActionTypes.HANDLE_CLOSE_SETTINGS_FORM,
});

export const handleSubmitSettingsForm = (studyId, fieldId, values) => ({
  type: ActionTypes.HANDLE_SUBMIT_SETTINGS_FORM,
  studyId: studyId,
  fieldId: fieldId,
  values: values,
});

export const handleSettingsFormSuccess = (field) => ({
  type: ActionTypes.HANDLE_SETTINGS_FORM_SUCCESS,
  field: field,
});

export const handleSettingsFormError = (errors) => ({
  type: ActionTypes.HANDLE_SETTINGS_FORM_ERROR,
  errors: errors,
});

export const handleOpen = (field) => ({
  type: "EDIT_TYPE_OPEN",
  field: field,
});

export const handleClose = () => ({
  type: "EDIT_TYPE_CLOSE",
});

export const handleFieldTypeChange = (fieldType) => ({
  type: "EDIT_TYPE_FIELD_TYPE_CHANGE",
  fieldType: fieldType,
});

export const handleWidgetChange = (widgetType) => ({
  type: "EDIT_TYPE_WIDGET_TYPE_CHANGE",
  widgetType: widgetType,
});

export const handleUnitChange = (field, unit) => ({
  type: "EDIT_TYPE_UNIT_CHANGE",
  unit: unit,
});

export const handlePrecisionChange = (field, precision) => ({
  type: "EDIT_TYPE_PRECISION_CHANGE",
  precision: precision,
});

export const handleLookupChange = (lookupId, dynamicLookupId) => ({
  type: "EDIT_TYPE_LOOKUP_CHANGE",
  lookupId: lookupId,
  dynamicLookupId: dynamicLookupId,
});

export const handleSubmit = (
  studyId,
  fieldId,
  fieldType,
  widgetType,
  unit,
  precision,
  lookupId,
  dynamicLookupId,
  newLookup,
  newLookupOptions
) => ({
  type: "EDIT_TYPE_SUBMIT",
  studyId: studyId,
  fieldId: fieldId,
  fieldType: fieldType,
  widgetType: widgetType,
  unit: unit,
  precision: precision,
  lookupId: lookupId,
  dynamicLookupId: dynamicLookupId,
  newLookup: newLookup,
  newLookupOptions: newLookupOptions,
});

export const handleSubmitSuccess = (data) => ({
  type: ActionTypes.EDIT_TYPE_SUBMIT_SUCCESS,
  data: data,
});

export const handleEditFieldTypeError = (field) => ({
  type: ActionTypes.EDIT_FIELD_TYPE_ERROR,
  field: field,
});

export const handleAddLookupOption = () => ({
  type: ActionTypes.ADD_LOOKUP_OPTION,
});

export const handleReorderLookupOption = (result) => ({
  type: ActionTypes.REORDER_LOOKUP_OPTION,
  result: result,
});

export const handleRemoveLookupOption = (index) => ({
  type: ActionTypes.REMOVE_LOOKUP_OPTION,
  index: index,
});

export const handleNewLookupValueChange = (field, value) => ({
  type: ActionTypes.HANDLE_NEW_LOOKUP_VALUE_CHANGE,
  field: field,
  value: value,
});

export const handleNewLookupOptionValueChange = (index, field, value) => ({
  type: ActionTypes.HANDLE_NEW_LOOKUP_OPTION_VALUE_CHANGE,
  index: index,
  field: field,
  value: value,
});

export const handleEditableUpdate = (fieldId, identifier, newValue) => ({
  type: ActionTypes.UPDATE_EDITABLE,
  fieldId: fieldId,
  identifier: identifier,
  newValue: newValue,
});

export const handleOpenNewValidationRulesForm = (field) => ({
  type: ActionTypes.HANDLE_OPEN_NEW_VALIDATION_RULES_FORM,
  field: field,
});

export const handleNewValidationRulesFormSubmit = (
  studyId,
  fieldId,
  values
) => ({
  type: ActionTypes.HANDLE_NEW_VALIDATION_RULES_FORM_SUBMIT,
  studyId: studyId,
  fieldId: fieldId,
  values: values,
});

export const handleEditValidationRulesFormSubmit = (
  validationRuleId,
  values
) => ({
  type: ActionTypes.HANDLE_EDIT_VALIDATION_RULES_FORM_SUBMIT,
  validationRuleId: validationRuleId,
  values: values,
});

export const handleNewValidationRulesFormError = (errors) => ({
  type: ActionTypes.HANDLE_NEW_VALIDATION_RULES_FORM_ERROR,
  errors: errors,
});

export const handleEditValidationRulesFormError = (errors) => ({
  type: ActionTypes.HANDLE_EDIT_VALIDATION_RULES_FORM_ERROR,
  errors: errors,
});

export const handleNewValidationRulesFormSuccess = (responseData) => ({
  type: ActionTypes.HANDLE_NEW_VALIDATION_RULES_FORM_SUCCESS,
  responseData: responseData,
});

export const handleEditValidationRulesFormSuccess = (fields) => ({
  type: ActionTypes.HANDLE_EDIT_VALIDATION_RULES_FORM_SUCCESS,
  fields: fields,
});

export const handleOpenEditValidationRulesForm = (field, validation) => ({
  type: ActionTypes.HANDLE_OPEN_EDIT_VALIDATION_RULES_FORM,
  field: field,
  validation: validation,
});

export const updateValidationRulesFormValue = (fieldName, value) => ({
  type: ActionTypes.UPDATE_VALIDATION_RULES_FORM_VALUE,
  fieldName,
  value,
});

export const handleCloseValidationRulesForm = () => ({
  type: ActionTypes.HANDLE_CLOSE_VALIDATION_RULES_FORM,
});

export const handleGetFieldsForValidation = (fields) => ({
  type: ActionTypes.HANDLE_GET_FIELDS_FOR_VALIDATION,
  fields: fields,
});

export const handleDeleteValidation = (validationRuleId) => ({
  type: ActionTypes.HANDLE_DELETE_VALIDATION,
  validationRuleId: validationRuleId,
});

export const handleDeleteValidationSuccess = (fields) => ({
  type: ActionTypes.HANDLE_DELETE_VALIDATION_SUCCESS,
  fields: fields,
});

export const handleReferencedFieldsSuccess = (result) => ({
  type: ActionTypes.HANDLE_REFERENCED_FIELDS_SUCCESS,
  result,
});

export const handleReferencedFieldsFailure = () => ({
  type: ActionTypes.HANDLE_REFERENCED_FIELDS_FAILURE,
});

import ChangeObject from "./ChangeObject";

class FieldChange extends ChangeObject {
  constructor(change) {
    const fieldIdentifier = change.new_attributes
      ? change.new_attributes.identifier.value
      : change.original_attributes.identifier.value;

    const formOrSubformIdentifier = change.new_attributes
      ? change.new_attributes.subheading_information.identifier.value
      : change.original_attributes.subheading_information.identifier.value;

    const elementType = "Field";
    const elementLocation = `${formOrSubformIdentifier}.${fieldIdentifier}`;

    const attributeData = [
      ["Identifier", "identifier"],
      [
        "Label",
        "label",
        {
          displayFunction: (value) => {
            return value.length > 0 ? value : "<em>Empty</em>";
          },
        },
      ],
      ["Type", "human_type_name"],
      [
        "Lookup",
        undefined,
        {
          originalValue: change.original_attributes?.lookup?.identifier?.value,
          newValue: change.new_attributes?.lookup?.identifier?.value,
          changed:
            change.original_attributes?.lookup?.identifier?.changed ||
            change.new_attributes?.lookup?.identifier?.changed,
        },
      ],
      ["Widget Type", "human_widget_type"],
      ["Unit", "unit"],
      ["Precision", "precision"],
      ["Calculation (custom)", "calculation"],
      ["Calculation (library)", "library_calculation_for_display"],
      [
        "Dynamic calculation",
        "dynamic_calculation",
        { attributeType: "boolean" },
      ],
      ["Dependencies", "parsed_dependencies"],
      ["Default Value", "default_value"],
      ["Do not export", "exclude_from_export", { attributeType: "boolean" }],
      ["Do not show", "do_not_show", { attributeType: "boolean" }],
      ["Field format", "field_format"],
      ["Hint", "hint"],
      ["Inline field", "inline_field", { attributeType: "boolean" }],
      ["Notes", "notes"],
      ["Table", "human_study_table"],
      ["Table Header", "table_header"],
      ["Hide Label", "hide_label", { attributeType: "boolean" }],
      [
        "Prevent submit on enter",
        "prevent_submit_on_enter",
        { attributeType: "boolean" },
      ],
    ];

    super(change, elementType, elementLocation, attributeData);
  }
}

export default FieldChange;

import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const parseUnit = (unit) => {
  if (!unit) return null;

  // remove any spaces
  unit = unit.replace(/\s+/g, "");

  const unitElements = [];
  let i = 0;

  while (i < unit.length) {
    // handle superscript
    if (unit[i] === "^") {
      i++;
      let supNum = "";
      while (i < unit.length && /[0-9-]/.test(unit[i])) {
        // gather the digits after '^'
        supNum += unit[i];
        i++;
      }
      unitElements.push(<sup key={unitElements.length}>{supNum}</sup>);
      // handle subscript
    } else if (unit[i] === "_") {
      i++;
      let subNum = "";
      while (i < unit.length && /[0-9]/.test(unit[i])) {
        subNum += unit[i];
        i++;
      }
      unitElements.push(<sub key={unitElements.length}>{subNum}</sub>);
      // handle any other letters
    } else {
      unitElements.push(<span key={unitElements.length}>{unit[i]}</span>);
      i++;
    }
  }

  return unitElements;
};

const TypeWidgetCell = ({ field, widgetLabel, handleOpen }) => {
  const widgetTypeRegex = /[\(\)]+/g;

  // Helper function to parse unit strings and replace ^ with <sup> and _ with <sub>

  const displayWidgetLabel = () => {
    if (widgetLabel === undefined) {
      return;
    }

    const splitWidgetLabel = widgetLabel.split(" ");
    if (splitWidgetLabel[splitWidgetLabel.length - 1].match(widgetTypeRegex)) {
      const newWidgetType = splitWidgetLabel.pop();

      return (
        <>
          {splitWidgetLabel.join(" ")}
          &nbsp;
          <em className="text-muted">{newWidgetType}</em>
        </>
      );
    } else {
      return widgetLabel;
    }
  };

  const lookupTooltip = () => {
    return (
      <Tooltip id="tooltip">
        {field.lookup_json.lookup_options.map((lookupOption) => {
          return (
            <div key={lookupOption.id}>
              {`${lookupOption.identifier} => ${lookupOption.label}`}
            </div>
          );
        })}
        {field.lookup_json.lookup_options.length == 0 && <div>None</div>}
      </Tooltip>
    );
  };

  const isDynamicLookup = () => {
    return !!field.dynamic_lookup_id;
  };

  const isLookupVisible = () => {
    return (
      field.field_type === "FormFields::SingleSelectType" ||
      field.field_type === "FormFields::MultipleSelectType"
    );
  };

  const isLookupPresent = () => {
    return field.lookup_json;
  };

  const isDynamicLookupPresent = () => {
    return field.dynamic_lookup_json;
  };

  const lookupIdentifier = (identifier) => {
    return (
      <div className="small lookup-label">
        <span>
          <i className="fa fa-book margin-right-5" />
        </span>
        <span className="lookup">{identifier}</span>
      </div>
    );
  };

  return (
    <>
      <td className="vertical-center">
        <div className="cell-with-action-button">
          <button
            onClick={(e) => handleOpen(field)}
            title="Edit the type of this field"
            className="btn btn-link action-button"
          >
            <i className="fa fa-edit" />
          </button>
          <span>
            <strong>
              {
                field.field_type_options.find((option) => {
                  return option.value === field.field_type;
                }).label
              }
            </strong>
            {field.precision && (
              <>
                &nbsp;
                <em className="small">({field.precision} d.p.)</em>
              </>
            )}
            {field.unit && (
              <div className="small">Unit: {parseUnit(field.unit)}</div>
            )}
            {isLookupVisible() && isLookupPresent() && (
              <OverlayTrigger placement="right" overlay={lookupTooltip()}>
                {lookupIdentifier(field.lookup_json.identifier)}
              </OverlayTrigger>
            )}
            {isLookupVisible() &&
              !isLookupPresent() &&
              !isDynamicLookup &&
              lookupIdentifier("None")}
            {isLookupVisible() &&
              isDynamicLookup &&
              isDynamicLookupPresent() &&
              lookupIdentifier(field.dynamic_lookup_json.identifier)}
            <div className="small">{displayWidgetLabel()}</div>
          </span>
        </div>
      </td>
    </>
  );
};

export default TypeWidgetCell;

import React from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, ControlLabel, HelpBlock } from "react-bootstrap";

import { Select } from "components/helpers";
import NewLookup from "./NewLookup";

const LookupSettings = ({
  fieldType,
  lookupId,

  dynamicLookupId,
  lookupOptions,
  saving,
  errors,
  handleLookupChange,
}) => {
  const isLookupVisible = () => {
    return (
      fieldType === "FormFields::SingleSelectType" ||
      fieldType === "FormFields::MultipleSelectType"
    );
  };

  const addingNewLookup = () => {
    return isLookupVisible() && lookupId === "new_lookup";
  };

  // Select option based on whether option is dynamic
  const selectedOption = () => {
    return lookupOptions.find((option) => {
      if (option.dynamic) {
        return option.value === dynamicLookupId;
      } else {
        return option.value === lookupId;
      }
    });
  };

  const displayLookupOptions = (selectedOption) => {
    if (selectedOption && selectedOption.lookup_options) {
      let lookup_options = selectedOption.lookup_options;

      return (
        <FormGroup>
          <ControlLabel>Lookup Options</ControlLabel>
          {listLookupOptions(lookup_options)}
        </FormGroup>
      );
    } else {
      return <></>;
    }
  };

  const listLookupOptions = (lookupOptions) => {
    if (lookupOptions.length >= 1) {
      return (
        <ul className="list-unstyled margin-bottom-0">
          {lookupOptions.map((lookupOption) => {
            return (
              <li key={lookupOption.id} className="small">
                {`${lookupOption.identifier} => ${lookupOption.label}`}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <div className="text-muted small">None</div>;
    }
  };

  return (
    <>
      {isLookupVisible() && (
        <Row>
          <Col sm={12} md={6}>
            <FormGroup
              controlId="select-field-lookup"
              validationState={errors.field.lookup_id ? "error" : null}
            >
              <ControlLabel>
                <abbr title="required">*</abbr>
                &nbsp;Lookup
              </ControlLabel>
              <Select
                options={lookupOptions}
                className="select-field-lookup"
                classNamePrefix="select-lookup"
                value={selectedOption()}
                onChange={handleLookupChange}
                isDisabled={saving}
              />
              {errors.field.lookup_id && (
                <HelpBlock bsClass="inline-help-block">
                  {errors.field.lookup_id[0]}
                </HelpBlock>
              )}
            </FormGroup>
          </Col>
          <Col sm={12} md={4}>
            {displayLookupOptions(selectedOption())}
          </Col>
        </Row>
      )}
      {addingNewLookup() && <NewLookup />}
    </>
  );
};

const mapStateToProps = (state) => {
  let modalState, field, fieldType;
  if (state.addField.showForm) {
    modalState = state.addField;
    field = modalState.values;
    fieldType = field.type;
  } else {
    modalState = state.editTypeModal;
    field = modalState.field;
    fieldType = field.field_type;
  }
  return {
    fieldType: fieldType,
    lookupId: field.lookup_id,
    lookupOptions: modalState.lookupOptions,
    saving: modalState.saving,
    errors: modalState.errors,
  };
};

export default connect(mapStateToProps)(LookupSettings);

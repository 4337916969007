import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ButtonToolbar, Button, Alert } from "react-bootstrap";

import actions from "actions/fields_page";
import FieldTypeSettings from "./FieldTypeSettings";
import LookupSettings from "./LookupSettings";

const EditTypeScreen = ({
  shown,
  saving,
  formErrors,
  studyId,
  fieldId,
  lookupId,
  originalLookupId,
  dynamicLookupId,
  originalDynamicLookupId,
  identifier,
  fieldType,
  newLookup,
  newLookupOptions,
  availableTypes,
  widgetType,
  unit,
  precision,
  handleClose,
  handleFieldTypeChange,
  handleWidgetChange,
  handleLookupChange,
  handleUnitChange,
  handlePrecisionChange,
  handleSubmit,
}) => {
  const selectedType = () => {
    return availableTypes.find((option) => {
      return option.value === fieldType;
    });
  };

  const warningSettingsToRemoveVisible = () => {
    if (
      selectedType() &&
      selectedType().removed_conversion_settings.length > 0
    ) {
      return selectedType().removed_conversion_settings;
    }
  };

  const warningLookupChange = () => {
    if (
      selectedType() &&
      selectedType().value === "FormFields::MultipleSelectType"
    ) {
      const lookupIdChanged =
        lookupId &&
        originalLookupId &&
        lookupId !== "new_lookup" &&
        lookupId !== originalLookupId;
      const dynamicLookupIdChanged =
        dynamicLookupId &&
        originalDynamicLookupId &&
        dynamicLookupId !== "new_lookup" &&
        dynamicLookupId !== originalDynamicLookupId;

      // Return true if either lookupId or dynamicLookupId has changed
      if (lookupIdChanged || dynamicLookupIdChanged) {
        return true;
      }
    }

    return false;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(
      studyId,
      fieldId,
      fieldType,
      widgetType,
      unit,
      precision,
      lookupId,
      dynamicLookupId,
      newLookup,
      newLookupOptions
    );
  };

  const lookupChange = (option) => {
    if (option.dynamic) {
      handleLookupChange(null, option.value);
    } else {
      handleLookupChange(option.value, null);
    }
  };

  return (
    <Modal
      id="modalWindow"
      backdrop="static"
      show={shown}
      onHide={saving ? undefined : handleClose}
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>Edit Data Type for {identifier}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formErrors.base && (
            <Alert bsStyle="danger">{formErrors.base[0]}</Alert>
          )}
          <FieldTypeSettings
            handleFieldTypeChange={(option) =>
              handleFieldTypeChange(option.value)
            }
            handleWidgetChange={(option) => handleWidgetChange(option.value)}
            handleUnitChange={handleUnitChange}
            handlePrecisionChange={handlePrecisionChange}
          />
          <LookupSettings
            handleLookupChange={(option) => {
              lookupChange(option);
            }}
            dynamicLookupId={dynamicLookupId}
          />
          {warningSettingsToRemoveVisible() && (
            <Alert bsStyle="warning">
              <strong>
                By changing field type, any stored values in the following
                attributes will be removed:
              </strong>
              {warningSettingsToRemoveVisible().map((setting, index) => {
                return (
                  <p key={index}>{`${setting.label}: ${setting.value}`}</p>
                );
              })}
            </Alert>
          )}
          {warningLookupChange() && (
            <Alert bsStyle="warning">
              <strong>
                Changing the lookup will delete any CONTAINS/NOT CONTAINS
                validation rules
              </strong>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" type="submit" disabled={saving}>
              {saving && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                  Saving
                </span>
              )}
              {!saving && (
                <span>
                  <i className="fa fa-check margin-right-5" />
                  Save
                </span>
              )}
            </Button>
            <div className="pull-right">
              <Button bsStyle="default" disabled={saving} onClick={handleClose}>
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const modalState = state.editTypeModal;
  const field = modalState.field;
  return {
    shown: modalState.shown,
    saving: modalState.saving,
    studyId: state.currentFormOrSubform.study_id,
    fieldId: field.id,
    originalLookupId: modalState.originalLookupId,
    lookupId: field.lookup_id || "",
    originalDynamicLookupId: modalState.originalDynamicLookupId,
    dynamicLookupId: field.dynamic_lookup_id || "",
    identifier: field.identifier,
    fieldType: field.field_type,
    availableTypes: field.field_type_options || [],
    widgetType: field.widget_type,
    unit: field.unit,
    precision: field.precision,
    newLookup: modalState.newLookup,
    newLookupOptions: modalState.newLookupOptions,
    formErrors: modalState.errors.field,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleClose: actions.handleClose,
      handleFieldTypeChange: actions.handleFieldTypeChange,
      handleWidgetChange: actions.handleWidgetChange,
      handleLookupChange: actions.handleLookupChange,
      handleUnitChange: actions.handleUnitChange,
      handlePrecisionChange: actions.handlePrecisionChange,
      handleSubmit: actions.handleSubmit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTypeScreen);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Modal,
  Row,
  Col,
  ButtonToolbar,
  Button,
  Checkbox,
  Alert,
} from "react-bootstrap";
import _ from "lodash";

import { TextField, TextAreaField, SelectField } from "components/helpers";
import actions from "actions/fields_page";
import FieldFormatDropdown from "./FieldFormatDropdown";
import StudyTableDropdown from "./StudyTableDropdown";

import TextFieldInputGroup from "../helpers/TextFieldInputGroup";
import TextSelectInputGroup from "../helpers/TextSelectInputGroup";

const TextFieldOrSelectInputGroup = ({ optionsForSelect, ...props }) => {
  return (
    <>
      {optionsForSelect.length == 0 && <TextFieldInputGroup {...props} />}
      {optionsForSelect.length >= 1 && (
        <TextSelectInputGroup {...props} optionsForSelect={optionsForSelect} />
      )}
    </>
  );
};

const EditSettingsFormScreen = ({
  show,
  saving,
  field,
  values,
  errors,
  allFieldsForCalculations,
  localFieldsForCalculations,
  libraryCalculationOptions,
  tableColumnOptions,
  handleClose,
  updateFormValue,
  handleSubmit,
}) => {
  const handleTableChange = (option) => {
    let value = null;
    if (option !== null) value = option.value;

    updateFormValue("study_table_id", value);
  };

  const handleLibraryCalculationChange = (option) => {
    let value = null;
    if (option !== null) {
      value = option.value;
    }
    updateFormValue("library_calculation_id", value);
  };

  const libraryCalculationAttributes = () => {
    const calculationData = libraryCalculationOptions.filter(
      (option) => option.value == values.library_calculation_id
    )[0];
    if (calculationData) {
      return calculationData.attributes;
    } else {
      return [];
    }
  };

  const getLibraryCalculationAttributeValue = (attribute) => {
    return values.library_calculation_attributes[attribute];
  };

  const handleLibraryCalculationAttributeChange = (attribute) => {
    // This returns a function that onChange can call,
    // but we also know the required attribute to amend
    return (field, option) => {
      updateFormValue("library_calculation_attributes", {
        ...values.library_calculation_attributes,
        [attribute]: option,
      });
    };
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(field.study_id, field.id, values);
  };

  const availableSetting = (setting) => {
    if (field && field.available_settings) {
      return field.available_settings.indexOf(setting) > -1;
    } else {
      return false;
    }
  };

  return (
    <Modal
      id="modalWindow"
      dialogClassName="modal-lg"
      backdrop="static"
      show={show}
      onHide={saving ? undefined : handleClose}
      className="validation-form-modal"
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>{`Edit field settings for ${field.identifier}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.base && <Alert bsStyle="danger">{errors.base[0]}</Alert>}
          <Row>
            {availableSetting("hint") &&
              field.field_type != "FormFields::DisplayItemType" && (
                <Col sm={6}>
                  <TextField
                    controlId="hint"
                    fieldName="hint"
                    label="Hint"
                    value={values.hint}
                    disabled={saving}
                    errors={errors.hint}
                    onChange={updateFormValue}
                    autoFocus={true}
                  />
                </Col>
              )}
            {availableSetting("default_value") &&
              field.field_type != "FormFields::DisplayItemType" && (
                <Col sm={6}>
                  <TextField
                    controlId="default_value"
                    fieldName="default_value"
                    label="Default value"
                    value={values.default_value}
                    disabled={saving}
                    errors={errors.default_value}
                    onChange={updateFormValue}
                  />
                </Col>
              )}
            {availableSetting("field_format") &&
              field.field_type === "FormFields::StringType" && (
                <Col sm={6}>
                  <TextField
                    controlId="field_format"
                    fieldName="field_format"
                    label="Format"
                    value={values.field_format}
                    disabled={saving}
                    errors={errors.field_format}
                    onChange={updateFormValue}
                  />
                </Col>
              )}
            {availableSetting("field_format") && (
              <FieldFormatDropdown
                colSize={6}
                handleFormatChange={(option) =>
                  updateFormValue("field_format", option.value)
                }
              />
            )}
          </Row>
          <Row>
            {availableSetting("notes") && (
              <Col sm={6}>
                <TextAreaField
                  controlId="notes"
                  fieldName="notes"
                  label="Notes"
                  value={values.notes}
                  disabled={saving}
                  errors={errors.notes}
                  onChange={updateFormValue}
                />
              </Col>
            )}
            {availableSetting("study_table") && (
              <Col sm={6}>
                <Row>
                  <StudyTableDropdown
                    colSize={12}
                    handleTableChange={handleTableChange}
                  />
                  {values.study_table_id && (
                    <Col sm={12}>
                      <TextField
                        controlId="study_table_row_label"
                        fieldName="study_table_row_label"
                        label="Table row label"
                        value={values.study_table_row_label}
                        disabled={saving || values.inline_field == true}
                        errors={errors.study_table_row_label}
                        onChange={updateFormValue}
                      />
                    </Col>
                  )}
                  {values.study_table_id && (
                    <Col sm={12}>
                      <SelectField
                        fieldName="table_header"
                        label="Table Column"
                        value={values.table_header}
                        options={tableColumnOptions[values.study_table_id]}
                        onChange={(option) =>
                          updateFormValue("table_header", option.value)
                        }
                        errors={errors.table_header}
                        isMulti={false}
                        isClearable={true}
                        required={false}
                        disabled={saving || values.inline_field == true}
                        classNamePrefix="select-table-header"
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Row>
          {availableSetting("calculation") && (
            <fieldset>
              <legend>
                {field.dynamic_lookup_id ? "Dynamic lookup" : "Calculation"}
              </legend>
              <Row>
                <Col sm={6}>
                  <SelectField
                    fieldName="library_calculation_id"
                    label="Library calculation"
                    value={values.library_calculation_id}
                    options={libraryCalculationOptions}
                    onChange={handleLibraryCalculationChange}
                    errors={errors.library_calculation_id}
                    placeholder="Select library calculation OR define a custom calculation"
                    isMulti={false}
                    isClearable={true}
                    required={false}
                    disabled={field.dynamic_lookup_id !== null || saving}
                    className="select-library-calculation my-2"
                    classNamePrefix="select-library-calculation"
                    onFocus={(e) =>
                      field.dynamic_lookup_id !== null && e.target.blur()
                    }
                  />
                </Col>
                <Col sm={6}>
                  {(!_.isNumber(values.library_calculation_id) && (
                    <TextAreaField
                      controlId="calculation"
                      fieldName="calculation"
                      label="Custom calculation"
                      value={values.calculation}
                      disabled={saving}
                      errors={errors.calculation}
                      onChange={updateFormValue}
                    />
                  )) ||
                    libraryCalculationAttributes().map((attribute) => {
                      return (
                        <TextFieldOrSelectInputGroup
                          key={"calc_attribute" + attribute}
                          label={attribute}
                          value={getLibraryCalculationAttributeValue(attribute)}
                          disabled={saving}
                          onChange={handleLibraryCalculationAttributeChange(
                            attribute
                          )}
                          optionsForSelect={
                            values.dynamic_calculation
                              ? allFieldsForCalculations
                              : localFieldsForCalculations
                          }
                        />
                      );
                    })}
                </Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  {!field.dynamic_lookup_id && (
                    <Checkbox
                      disabled={saving}
                      checked={values.dynamic_calculation}
                      validationState={
                        errors.dynamic_calculation ? "error" : null
                      }
                      onChange={(e) =>
                        updateFormValue("dynamic_calculation", e.target.checked)
                      }
                    >
                      Dynamic Calculation
                    </Checkbox>
                  )}
                </Col>
              </Row>
            </fieldset>
          )}
          {field.field_type != "FormFields::DisplayItemType" && (
            <fieldset>
              <legend>Settings</legend>
              <Row>
                <Col sm={6}>
                  <Checkbox
                    disabled={saving}
                    checked={values.exclude_from_export}
                    validationState={
                      errors.exclude_from_export ? "error" : null
                    }
                    onChange={(e) =>
                      updateFormValue("exclude_from_export", e.target.checked)
                    }
                  >
                    Do not export this field
                  </Checkbox>
                </Col>
                <Col sm={6}>
                  <Checkbox
                    disabled={
                      saving ||
                      values.study_table_id !== null ||
                      values.do_not_show == true
                    }
                    checked={values.inline_field}
                    validationState={errors.inline_field ? "error" : null}
                    onChange={(e) =>
                      updateFormValue("inline_field", e.target.checked)
                    }
                  >
                    Inline with the next field
                  </Checkbox>
                </Col>
                {values.inline_field == true && (
                  <Col sm={6}>
                    <TextField
                      controlId="inline_field_label"
                      fieldName="inline_field_label"
                      label="Inline Field Label"
                      value={values.inline_field_label}
                      disabled={saving}
                      errors={errors.inline_field_label}
                      onChange={updateFormValue}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col sm={6}>
                  <Checkbox
                    disabled={saving}
                    checked={values.do_not_show}
                    validationState={errors.do_not_show ? "error" : null}
                    onChange={(e) =>
                      updateFormValue("do_not_show", e.target.checked)
                    }
                  >
                    Do not show this field on screen
                  </Checkbox>
                </Col>
                <Col sm={6}>
                  <Checkbox
                    disabled={saving}
                    checked={values.hide_label}
                    validationState={errors.hide_label ? "error" : null}
                    onChange={(e) =>
                      updateFormValue("hide_label", e.target.checked)
                    }
                  >
                    Hide the label for this field
                  </Checkbox>
                </Col>
                {(field.widget_type == "text_box" ||
                  field.widget_type == "text_box_small") && (
                  <Col sm={6}>
                    <Checkbox
                      disabled={saving}
                      checked={values.prevent_submit_on_enter}
                      validationState={
                        errors.prevent_submit_on_enter ? "error" : null
                      }
                      onChange={(e) =>
                        updateFormValue(
                          "prevent_submit_on_enter",
                          e.target.checked
                        )
                      }
                    >
                      Prevent form submission on enter to support barcode
                      scanners
                    </Checkbox>
                  </Col>
                )}
              </Row>
              <Row>
                {field.fieldable_type == "Subform" &&
                field.field_type == "FormFields::SingleSelectType" ? (
                  // TODO: is this causing a js error on save?
                  <Col sm={6}>
                    <Checkbox
                      disabled={saving}
                      checked={values.prepopulate_subform}
                      validationState={
                        errors.prepopulate_subform ? "error" : null
                      }
                      onChange={(e) =>
                        updateFormValue("prepopulate_subform", e.target.checked)
                      }
                    >
                      Prepopulate subform row using this field
                    </Checkbox>
                  </Col>
                ) : null}
              </Row>
            </fieldset>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" type="submit" disabled={saving}>
              {saving && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                  Saving
                </span>
              )}
              {!saving && (
                <span>
                  <i className="fa fa-check margin-right-5" />
                  Save
                </span>
              )}
            </Button>
            <div className="pull-right">
              <Button bsStyle="default" disabled={saving} onClick={handleClose}>
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.editSettings;

  return {
    show: relevantState.showForm,
    field: relevantState.field,
    values: relevantState.values,
    saving: relevantState.saving,
    errors: relevantState.errors,
    allFieldsForCalculations: relevantState.allFieldsForCalculations,
    localFieldsForCalculations: relevantState.localFieldsForCalculations,
    libraryCalculationOptions: relevantState.libraryCalculationOptions,
    tableColumnOptions: relevantState.tableColumnOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFormValue: actions.updateSettingsFormValue,
      handleClose: actions.handleCloseSettingsForm,
      handleSubmit: actions.handleSubmitSettingsForm,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSettingsFormScreen);

import React from "react";
import { Row, Col, HelpBlock, Checkbox } from "react-bootstrap";

import TextField from "../helpers/TextField";
import TextFieldInputGroup from "../helpers/TextFieldInputGroup";
import SelectInputGroup from "../helpers/SelectInputGroup";
import TextSelectInputGroup from "../helpers/TextSelectInputGroup";

const TextFieldOrSelectInputGroup = ({ optionsForSelect, ...props }) => {
  return (
    <>
      {optionsForSelect.length == 0 && <TextFieldInputGroup {...props} />}
      {optionsForSelect.length >= 1 && (
        <TextSelectInputGroup {...props} optionsForSelect={optionsForSelect} />
      )}
    </>
  );
};

const EqualsTypeConfig = ({
  values,
  saving,
  errors,
  updateFormValue,
  selectedType,
  collections,
  availableFields,
}) => {
  const commonProps = {
    label: "Equal to",
    field: "equal_to",
    disabled: saving,
    value: values.equal_to,
    errors: errors.equal_to,
    onChange: updateFormValue,
  };

  return (
    <Row className="margin-top-20">
      <Col lg={6}>
        {selectedType === "Boolean" && (
          <SelectInputGroup
            {...commonProps}
            includeBlank={true}
            optionsForSelect={collections.boolean_equal}
          />
        )}
        {selectedType !== "Boolean" && (
          <TextFieldOrSelectInputGroup
            {...commonProps}
            hasErrors={errors.equal_to}
            optionsForSelect={availableFields}
          />
        )}
      </Col>
    </Row>
  );
};

const NotEqualsTypeConfig = ({
  values,
  saving,
  errors,
  updateFormValue,
  availableFields,
}) => {
  return (
    <Row className="margin-top-20">
      <Col lg={6}>
        <TextFieldOrSelectInputGroup
          label="Not equal to"
          field="not_equal_to"
          value={values.not_equal_to}
          disabled={saving}
          hasErrors={errors.not_equal_to}
          errors={errors.not_equal_to}
          onChange={updateFormValue}
          optionsForSelect={availableFields}
        />
      </Col>
    </Row>
  );
};

const RangeTypeConfig = ({
  values,
  saving,
  errors,
  updateFormValue,
  availableFields,
}) => {
  return (
    <>
      <p className="bg-info padding-top-10 padding-bottom-10 padding-left-10 padding-right-10 margin-top-20">
        <span>You may use expressions in the range parameters, e.g. </span>
        <strong>field_identifier + 30</strong>.
        <span>
          {" "}
          If the field reference is a date then you can write expressions such
          as{" "}
        </span>
        <strong>field_date_identifier + 1w2d</strong>.
      </p>
      <h4>Hard validations</h4>
      <Row>
        <Col lg={6}>
          <TextFieldOrSelectInputGroup
            label="Min"
            field="min_value"
            value={values.min_value}
            disabled={saving}
            errors={errors.min_value}
            onChange={updateFormValue}
            optionsForSelect={availableFields}
          />
        </Col>
        <Col lg={6}>
          <TextFieldOrSelectInputGroup
            label="Max"
            field="max_value"
            value={values.max_value}
            disabled={saving}
            errors={errors.max_value}
            onChange={updateFormValue}
            optionsForSelect={availableFields}
          />
        </Col>
      </Row>
      <h4>Soft validations</h4>
      <Row>
        <Col lg={6}>
          <TextFieldOrSelectInputGroup
            label="Low"
            field="low_value"
            value={values.low_value}
            disabled={saving}
            errors={errors.low_value}
            onChange={updateFormValue}
            optionsForSelect={availableFields}
          />
        </Col>
        <Col lg={6}>
          <TextFieldOrSelectInputGroup
            label="High"
            field="high_value"
            value={values.high_value}
            disabled={saving}
            errors={errors.high_value}
            onChange={updateFormValue}
            optionsForSelect={availableFields}
          />
        </Col>
      </Row>
      {errors.range_parameters && (
        <HelpBlock bsClass="inline-help-block">
          {errors.range_parameters[0]}
        </HelpBlock>
      )}
    </>
  );
};

const ContainsTypeConfig = ({
  values,
  saving,
  errors,
  updateFormValue,
  lookupOptions,
}) => {
  return (
    <>
      <Row className="margin-top-20">
        <Col lg={6}>
          <SelectInputGroup
            label="Contains"
            field="equal_to"
            includeBlank={true}
            value={values.equal_to}
            disabled={saving}
            errors={errors.equal_to}
            onChange={updateFormValue}
            optionsForSelect={lookupOptions}
          />
        </Col>
      </Row>
    </>
  );
};

const NotContainsTypeConfig = ({
  values,
  saving,
  errors,
  updateFormValue,
  lookupOptions,
}) => {
  return (
    <>
      <Row className="margin-top-20">
        <Col lg={6}>
          <SelectInputGroup
            label="Not contains"
            field="not_equal_to"
            includeBlank={true}
            value={values.not_equal_to}
            disabled={saving}
            errors={errors.not_equal_to}
            onChange={updateFormValue}
            optionsForSelect={lookupOptions}
          />
        </Col>
      </Row>
    </>
  );
};

const UniquenessTypeConfig = ({ values, saving, errors, updateFormValue }) => {
  return (
    <>
      <div className="uniqueness-checkboxes">
        <Checkbox
          disabled={saving}
          checked={values.unique_within_individual}
          validationState={errors.uniqueness_parameters ? "error" : null}
          onChange={(e) =>
            updateFormValue("unique_within_individual", e.target.checked)
          }
        >
          Unique within individual
        </Checkbox>
        <Checkbox
          disabled={saving}
          checked={values.unique_within_event}
          validationState={errors.uniqueness_parameters ? "error" : null}
          onChange={(e) =>
            updateFormValue("unique_within_event", e.target.checked)
          }
        >
          Unique within event
        </Checkbox>
        <Checkbox
          disabled={saving}
          checked={values.unique_within_subforms}
          validationState={errors.uniqueness_parameters ? "error" : null}
          onChange={(e) =>
            updateFormValue("unique_within_subforms", e.target.checked)
          }
        >
          Unique within subforms
        </Checkbox>
        <Checkbox
          disabled={saving}
          checked={values.unique_within_site}
          validationState={errors.uniqueness_parameters ? "error" : null}
          onChange={(e) =>
            updateFormValue("unique_within_site", e.target.checked)
          }
        >
          Unique within site
        </Checkbox>
        <Checkbox
          disabled={saving}
          checked={values.unique_within_study}
          validationState={errors.uniqueness_parameters ? "error" : null}
          onChange={(e) =>
            updateFormValue("unique_within_study", e.target.checked)
          }
        >
          Unique within study
        </Checkbox>
      </div>
      {errors.uniqueness_parameters && (
        <HelpBlock bsClass="inline-help-block">
          {errors.uniqueness_parameters[0]}
        </HelpBlock>
      )}
    </>
  );
};

const FormatCustomTypeConfig = ({
  values,
  saving,
  errors,
  updateFormValue,
}) => {
  return (
    <Row className="margin-top-20 margin-bottom-20">
      <Col lg={6}>
        <TextField
          controlId="custom_format_dsl"
          fieldName="custom_format_dsl"
          value={values.custom_format_dsl}
          disabled={saving}
          errors={errors.custom_format_dsl}
          onChange={updateFormValue}
        />
        <div className="padding-left-10">
          <p className="small margin-bottom-0">
            <strong>[AA]</strong> - Capital letters equal to the number of A
          </p>
          <p className="small margin-bottom-0">
            <strong>[aaaa]</strong> - Lowercase letters equal to the number of a
          </p>
          <p className="small margin-bottom-0">
            <strong>[nnn]</strong> - Number with digits equal to the numebr of n
          </p>
          <p className="small margin-bottom-0">
            <strong>{"<site_code>"}</strong> - Site code for the relevant
            individual
          </p>
        </div>
      </Col>
    </Row>
  );
};

const ValidationTypeConfig = (props) => {
  const { selectedRuleTypeIs } = props;
  return (
    <>
      {selectedRuleTypeIs("equal") && <EqualsTypeConfig {...props} />}
      {selectedRuleTypeIs("not_equal") && <NotEqualsTypeConfig {...props} />}
      {selectedRuleTypeIs("range") && <RangeTypeConfig {...props} />}
      {selectedRuleTypeIs("contain") && <ContainsTypeConfig {...props} />}
      {selectedRuleTypeIs("not_contain") && (
        <NotContainsTypeConfig {...props} />
      )}
      {selectedRuleTypeIs("uniqueness") && <UniquenessTypeConfig {...props} />}
      {selectedRuleTypeIs("format_custom") && (
        <FormatCustomTypeConfig {...props} />
      )}
    </>
  );
};

export default ValidationTypeConfig;

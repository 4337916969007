import React from "react";

import ValidationTypeButtons from "./ValidationTypeButtons";
import ValidationTypeConfig from "./ValidationTypeConfig";

const StandardValidationFields = ({
  validationTypes,
  errors,
  saving,
  editing,
  values,
  hasValidation,
  selectedRuleTypeIs,
  updateFormValue,
  selectedType,
  availableFields,
  lookupOptions,
  collections,
}) => {
  return (
    <div className="validation-fields">
      {!editing && (
        <ValidationTypeButtons
          validationTypes={validationTypes}
          errors={errors}
          saving={saving}
          hasValidation={hasValidation}
          selectedRuleTypeIs={selectedRuleTypeIs}
          updateFormValue={updateFormValue}
        />
      )}
      <ValidationTypeConfig
        selectedType={selectedType}
        selectedRuleTypeIs={selectedRuleTypeIs}
        values={values}
        saving={saving}
        errors={errors}
        updateFormValue={updateFormValue}
        availableFields={availableFields}
        collections={collections}
        lookupOptions={lookupOptions}
      />
    </div>
  );
};

const ValidationFields = ({
  field,
  saving,
  editing,
  collections,
  lookupOptions,
  availableFields,
  values,
  errors,
  updateFormValue,
  hasValidation,
}) => {
  const validationTypesMap = {
    Date: ["presence", "range", "generic", "equal", "not_equal", "uniqueness"],
    Text: ["presence", "generic", "equal", "not_equal"],
    Integer: [
      "presence",
      "range",
      "generic",
      "equal",
      "not_equal",
      "uniqueness",
    ],
    Decimal: ["presence", "range", "generic", "equal", "not_equal"],
    "Single select": ["presence", "generic", "equal", "not_equal"],
    "Multiple select": ["presence", "generic", "contain", "not_contain"],
    PartialDate: ["presence", "range", "generic", "equal", "not_equal"],
    Time: ["presence", "range", "generic", "equal", "not_equal"],
    Duration: ["presence", "range", "generic", "equal", "not_equal"],
    Boolean: ["generic", "equal"],
    "Site lookup": ["presence", "generic", "equal", "not_equal"],
    "Study lookup": ["presence", "generic", "equal", "not_equal"],
    FormBinding: ["form_exist", "form_not_exist"],
  };

  const selectedType = field.field_type_for_display;

  const selectedRuleTypeIs = (rule) => {
    return values.validation_rule_type && values.validation_rule_type === rule;
  };

  if (selectedType === "String") {
    return (
      <div className="validation-fields">
        {!editing && (
          <>
            <ValidationTypeButtons
              rowMargin={0}
              validationTypes={[
                "presence",
                "generic",
                "equal",
                "not_equal",
                "uniqueness",
              ]}
              errors={{}}
              saving={saving}
              hasValidation={hasValidation}
              selectedRuleTypeIs={selectedRuleTypeIs}
              updateFormValue={updateFormValue}
            />
            <ValidationTypeButtons
              validationTypes={[
                "format_custom",
                "format_email",
                "format_phone",
                "format_postcode",
                "format_short_postcode",
                "format_nhs_number",
              ]}
              errors={errors}
              saving={saving}
              hasValidation={hasValidation}
              selectedRuleTypeIs={selectedRuleTypeIs}
              updateFormValue={updateFormValue}
            />
          </>
        )}
        <ValidationTypeConfig
          selectedType={selectedType}
          selectedRuleTypeIs={selectedRuleTypeIs}
          values={values}
          saving={saving}
          errors={errors}
          updateFormValue={updateFormValue}
          availableFields={availableFields}
          collections={collections}
          lookupOptions={lookupOptions}
        />
      </div>
    );
  } else {
    return (
      <StandardValidationFields
        validationTypes={validationTypesMap[field.field_type_for_display]}
        editing={editing}
        availableFields={availableFields}
        errors={errors}
        saving={saving}
        values={values}
        hasValidation={hasValidation}
        selectedType={selectedType}
        collections={collections}
        lookupOptions={lookupOptions}
        selectedRuleTypeIs={selectedRuleTypeIs}
        updateFormValue={updateFormValue}
      />
    );
  }
};

export default ValidationFields;
